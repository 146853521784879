import {
	Area,
	AreaChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts'
import React from 'react'
import Skeleton from 'src/components/Skeleton'
import {fetchData} from 'src/hooks/useFetch'

export const CycleEfficiency = ({
	date,
	type = 'cycleRegisterEfficiency',
	minWidth = '860px',
}) => {
	const [data, setData] = React.useState()
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		const loadData = async () => {
			const response = await fetchData(
				`report/${type}?startDate=${date.startDate}&finalDate=${date.finalDate}`,
			)
			setData(response.reverse())

			setLoading(false)
		}

		loadData()
	}, [date.startDate, date.finalDate])

	return (
		<>
			{loading ? (
				<Skeleton height="400px" width="100%" />
			) : (
				<div
					style={{
						width: '100%',
						minWidth: minWidth,
						padding: '5px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<ResponsiveContainer width={parseInt(minWidth)} height={300}>
						<AreaChart
							width={parseInt(minWidth)}
							height={300}
							data={data}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5,
							}}>
							<defs>
								<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
									<stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
									<stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
								</linearGradient>
								<linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
									<stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
									<stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
								</linearGradient>
							</defs>
							{/* <CartesianGrid strokeDasharray="1" /> */}
							<XAxis
								dataKey="label"
								name="Por dia"
								format={(e) => {
									console.log(e)
								}}
							/>
							<YAxis dataKey="totalClosed" />
							<YAxis dataKey="totalOpened" />
							<Tooltip />
							{/* <Legend name='Por hora' dataKey='label' /> */}
							<Area
								type="monotone"
								name="Total Fechado"
								dataKey="totalClosed"
								stroke="#82ca9d"
								fillOpacity={0.4}
								fill="url(#colorPv)"
							/>
							<Area
								type="monotone"
								name="Total Aberto"
								dataKey="totalOpened"
								stroke="#38348c"
								fillOpacity={0.4}
								fill="url(#colorUv)"
							/>
						</AreaChart>
					</ResponsiveContainer>
				</div>
			)}
		</>
	)
}

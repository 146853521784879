import { Container, Option, Select } from './styles'
import { getItem, setItem } from 'src/utils/localStorage'
import { hideLoadingBlur, showLoadingBlur } from 'src/actions/index'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createFetchRequest } from 'src/genericFunctions'

const ClientSelect = () => {
	const userInfos = getItem('session')
	const [clients, setClients] = React.useState([])
	const [branchs, setBranchs] = React.useState([])

	const [selectedClient, setSelecteClient] = React.useState(() => {
		if (userInfos.profile_id === 1) {
			const selectedCli = getItem('selected_client')
			if (selectedCli !== null)
				return parseInt(selectedCli.selectedClient)
			else
				return undefined
		} else {
			return parseInt(userInfos.client_id)
		}
	})

	const [selectedBranch, setSelecteBranch] = React.useState(() => {
		const selectedCli = getItem('selected_branch')
		if (selectedCli !== null)
			return parseInt(selectedCli.selectedBranch)
		else
			return undefined
	})

	if (userInfos === null) window.location.reload()

	console.log(selectedClient)

	const loadPageData = React.useCallback(() => {
		showLoadingBlur()

		if (userInfos.profile_id === 1) {
			createFetchRequest(
				'client/listAll',
				'get',
				null,
				async ({ err, result }) => {
					if (err) setClients([])
					else if (!err && result) {
						setClients(result)
					}

					hideLoadingBlur()
				},
			)
		}

		createFetchRequest(
			`branch/getAll/${selectedClient}`,
			'get',
			null,
			async ({ err, result }) => {
				if (err) setBranchs([])
				else if (!err && result) {
					setBranchs(result)
				}

				hideLoadingBlur()
			},
		)
	}, [])

	React.useEffect(() => {
		loadPageData()
	}, [loadPageData])

	const changeClient = React.useCallback((client_id) => {
		if (client_id) {
			setSelecteClient(client_id)
			setItem('selected_client', { selectedClient: client_id })
			setItem('selectedClientName', clients.find(e => e.id === parseInt(client_id)).name)
		} else {
			setSelecteClient(undefined)
			setItem('selected_client', { selectedClient: undefined })
			setItem('selectedClientName', undefined)
		}

		setSelecteBranch(undefined)
		setItem('selected_branch', { selectedBranch: undefined })
		setItem('selectedBranchName', undefined)
		window.location.reload()
	}, [clients])

	const changeBranch = React.useCallback((branch_id) => {
		if (branch_id) {
			setSelecteBranch(branch_id)
			setItem('selected_branch', { selectedBranch: branch_id })
			setItem('selectedBranchName', branchs.find(e => e.id === parseInt(branch_id)).name)
		} else {
			setSelecteBranch(undefined)
			setItem('selected_branch', { selectedBranch: undefined })
			setItem('selectedBranchName', undefined)
		}
		window.location.reload()
	}, [branchs])

	return (
		<>
		    {userInfos.profile_id === 1 && (
				<Container>
					<Select onChange={(e) => changeClient(e.target.value)} defaultValue={selectedClient} value={selectedClient} defaultChecked={selectedClient}>
						<Option value={undefined} label='Todos os Clientes' />
						{clients.map((client, index) => (
							<Option key={index} value={client.id} label={client.name} />
						))}
					</Select>
				</Container>
			)}

			{selectedClient && branchs.length > 1 && (
				<Container>
					<Select onChange={(e) => changeBranch(e.target.value)} defaultValue={selectedBranch} value={selectedBranch} defaultChecked={selectedBranch}>
						<Option value={undefined} label='Todas as Sedes' />
						{branchs.map((client, index) => (
							<Option key={index} value={client.id} label={client.name} />
						))}
					</Select>
				</Container>
			)}
		</>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(ClientSelect)
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {ContentDiv, MainDiv, MenuDiv} from './styles'
import React, {useCallback, useEffect, useState} from 'react'
import {getItem, setItem} from 'src/utils/localStorage'

import InfoModal from 'src/components/InfoModal'
import LoadingBlur from 'src/components/LoadingBlur'
import Login from '../login'
import Menu from 'src/components/Menu'
import MyProfile from '../myProfile'
import pagesList from 'src/pagesAddressList'
import {updateApiHeader} from 'src/services/pdf-api'
import {useInfoModal} from 'src/hooks/useInfoModal'
import {useSession} from 'src/hooks/useSession'

//Import pages and Components
export default function App() {
	const {isLogged} = useSession()
	const {modal, hideModal} = useInfoModal()

	const [menuIsCollapsed, collapseMenu] = useState(
		getItem('menuIsCollapsed') === true ? true : false,
	)

	const handleCollpaseMenu = () => {
		collapseMenu(!menuIsCollapsed)
		setItem('menuIsCollapsed', !menuIsCollapsed)
	}

	const routes = CobeRoutes({isLogged})

	React.useEffect(() => {
		let client = getItem('selected_client')
		if (client !== null) client = client.selectedClient

		let branch = getItem('selected_branch')
		if (branch !== null) branch = branch.selectedBranch

		updateApiHeader({
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getItem('token')}`,
			SelectedClient: client,
			SelectedBranch: branch,
		})
	}, [])

	return (
		<BrowserRouter>
			<div
				className="App"
				style={{
					width: '100%',
					height: '100%',
					display: 'flex',
					flexDirection: 'row',
					overflow: 'hidden',
					gap: '10px',
					backgroundColor: '#EBEBEA',
				}}>
				<LoadingBlur />

				{isLogged ? (
					<MenuDiv menuIsCollapsed={menuIsCollapsed}>
						<Menu
							isCollapsed={menuIsCollapsed}
							collapseMenuFunction={handleCollpaseMenu}
						/>
					</MenuDiv>
				) : null}

				<MainDiv menuIsCollapsed={menuIsCollapsed}>
					<InfoModal modal={modal} closeModal={hideModal} />
					<ContentDiv>
						<Switch>{routes}</Switch>
					</ContentDiv>
				</MainDiv>
			</div>
		</BrowserRouter>
	)
}

function CobeRoutes({isLogged}) {
	const userInfos = getItem('session')
	const userProfile = userInfos ? userInfos.profile_id : null

	const [myRoutes, setRoutes] = useState(null)

	const constructRoutes = useCallback(() => {
		const returnCorrectPrivateRoute = ({page, profileToAccess}) => {
			if (
				(page.isAvaliable !== false && !profileToAccess) ||
				(page.isAvaliable !== false &&
					profileToAccess &&
					profileToAccess.indexOf(userProfile) !== -1)
			) {
				return (
					<Route
						key={page.id}
						exact
						path={page.address}
						component={page.content}
					/>
				)
			}
		}

		const loggedRoutes = []

		if (isLogged && userProfile && pagesList) {
			for (let i = 0; i < pagesList.length; i++) {
				const page = pagesList[i]

				if (page.type === 'list') {
					const subPages = page.list.map((subPage) => {
						const profileToAccess = subPage.profileToAccess
							? subPage.profileToAccess
							: null

						return returnCorrectPrivateRoute({
							page: subPage,
							profileToAccess,
						})
					})

					loggedRoutes.push(...subPages)
				} else {
					const profileToAccess = page.profileToAccess
						? page.profileToAccess
						: null

					loggedRoutes.push(returnCorrectPrivateRoute({page, profileToAccess}))
				}
			}
		} else {
			loggedRoutes.push(<Route path="*" component={Login} key={'login'} />)
		}

		loggedRoutes.push(
			<Route path="/MyProfile" component={MyProfile} key={'myProfile'} />,
		)

		setRoutes(loggedRoutes)
	}, [isLogged, userProfile])

	useEffect(() => {
		constructRoutes()
	}, [constructRoutes])

	return myRoutes
}

import {getItem, setItem} from 'src/utils/localStorage'
import api from 'src/services/api'
import {updateApiHeader} from 'src/services/pdf-api'

export const putData = async (url, data) => {
	let client = getItem('selected_client')
	if (client !== null) client = client.selectedClient

	let branch = getItem('selected_branch')
	if (branch !== null) branch = branch.selectedBranch

	const token = getItem('token')

	const res = await api.put(url, data, {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
			SelectedClient: client,
			SelectedBranch: branch,
		},
	})

	const body = res.data

	if (res.headers && res.headers.refreshtoken) {
		updateApiHeader({Authorization: `Bearer ${res.headers.refreshtoken}`})
		setItem('token', res.headers.refreshtoken)
	} else if (body && body.token) {
		updateApiHeader({Authorization: `Bearer ${body.token}`})
		setItem('token', body.token)
	}

	return body.data
}
